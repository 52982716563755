import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart } from "react-icons/fa"



import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';


import './AcStyle.css'

import ReactPaginate from 'react-paginate';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        className='col-md-9'
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      className: 'align-items-start',
    };
  }


function Account() {
    var customer_id = localStorage.getItem("customer_id");
    if(customer_id==null){
        window.location.href = window.location.origin + '/login';
    }
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState(''); 
    const [cpassword, setCpassword] = useState(''); 
    const [recentpassword, setRecentpassword] = useState(''); 
    const [jesdata, setJesda] = useState([]); 
    const [jesorder, setJesorder] = useState([]); 
    let [jescnt, setJescnt] = useState([]);
    const [value, setValue] = React.useState(0);
    const [error, setError] = useState(false);
    const [passworderror, setPassworderror] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(20);
    const [pageCount, setPageCount] = useState(0)


    const [selected,setSelected] = useState();
    const [responsedata,setResponsedata]= useState();
    const mediumViewport = useMediaQuery('(min-width:767px)');
    const handlePageClick = async (e) => {
        const selectedPage = e.selected;
        console.log(selectedPage*perPage)
        setOffset(selectedPage*perPage)
    }
    var formData = new FormData();
    async function getData(){
        var requestdata = {
            customer_id: customer_id
        };
        formData.append('customer', JSON.stringify(requestdata));
        let requestOptions = {
            method: 'POST',
            body: formData 
        };
        var pulldata = await fetch("http://3.20.64.228/control_panel/api/customers/fetchdetails",requestOptions);
        var userdata = await pulldata.json();          
        var user_response_code = userdata.status;
        
        setJesda(userdata.data);
        setSelected(userdata.data.gender);
    }
    async function getOrdersData(){
        var requestdata = {
            customer_id: customer_id,
            offset: offset,
        };
        formData.append('customer', JSON.stringify(requestdata));
        let requestOptions = {
            method: 'POST',
            body: formData 
        };
        var pulldata = await fetch("http://3.20.64.228/control_panel/api/customers/fetchorders",requestOptions);
        var userdata = await pulldata.json();          
        var user_response_code = userdata.status;
        
        setJesorder(userdata.data);

        var respcnt = await fetch("http://3.20.64.228/control_panel/api/customers/count",requestOptions);
        var datacnt = await respcnt.json();
        setJescnt(datacnt.data);
        setPageCount(Math.ceil(jescnt/perPage))
        ///setSelected(userdata.data.gender);
    }
    useEffect(()=>{
        getData();
        getOrdersData();
    },[jescnt,offset]);
    //console.log(jesdata)
    const handleSubmit = (e) => {
        e.preventDefault();
        var name = document.getElementById('fname').value;
        var lname = document.getElementById('lname').value;
        var email = document.getElementById('email').value;
        var mobile = document.getElementById('mobile_no').value;
        if (name === '' || lname === '' || email === '' || mobile ==='' ) {
            setError(true);
        }else{
            submitdata();
        }
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const handleCpassword = (e) => {
        setCpassword(e.target.value);
    };
    const handleRecentassword = (e) => {
        setRecentpassword(e.target.value);
    };
    const changePassword = (e) => {
        if(password===cpassword){
            change_password();
        }else{
            setPassworderror("Password mismatch")
        }
    }    
    const errorMessage = () => {
        return (
            <>
            {error ?
            <div className="error alert d-flex align-items-center alert-danger">
                Please enter all the fields
            </div>
            :
            ''}
            </>
        );
    };
    async function  submitdata (){
        var radios = document.getElementsByName('gender');
            for (var radio of radios)
            {
                if (radio.checked) {
                   var gender =radio.value;
                }
            }
        var requestdata = {
            customer_id: customer_id,
            fname: document.getElementById('fname').value,
            lname: document.getElementById('lname').value,
            email: document.getElementById('email').value,
            mobile_no: document.getElementById('mobile_no').value,
            gender:gender
        };
        var formData= new FormData();
        formData.append('customer', JSON.stringify(requestdata));
        var requestOptions = {
            method: 'POST',
            body: formData
        }
        var resp = await fetch("http://3.20.64.228/control_panel/api/auth/update_profile",requestOptions);
        var data = await resp.json(); 
        console.log(data);
        console.log(data.message)
        setResponsedata(data.message);
        // setResponsestatus(data.status);
    }
    async function change_password(){
        var requestdata = {
            customer_id: customer_id,
            password:password,
            recent_password:recentpassword
        };
        var formData= new FormData();
        formData.append('customer_data', JSON.stringify(requestdata));
        var requestOptions = {
            method: 'POST',
            body: formData
        }
        var resp = await fetch("http://3.20.64.228/control_panel/api/auth/change_password",requestOptions);
        var data = await resp.json(); 
        console.log(data);
        console.log(data.message)
        setPassworderror(data.message);
        // setResponsestatus(data.status);
    }
    const handleChange1 = (event) => {
        setSelected(event.target.value)
    }
  return (
    <>
        <main>
        <div className="my-account-wrapper section-padding">
            <div className="container">
                <div className="section-bg-color">
                    <div className="row">
                        <div className="col-12">
                            <Box className='row g-0' sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} >
                                <Tabs
                                    className='col-md-3'
                                    //orientation="vertical"
                                    orientation={mediumViewport ? "vertical" : "horizontal"}
                                    //variant="scrollable"
                                    variant={mediumViewport ? "" : "scrollable"}
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Section Account"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label="My Details" {...a11yProps(0)} />
                                    <Tab label="My Orders" {...a11yProps(1)} />
                                    <Tab label="Wish List" {...a11yProps(2)} />
                                    <Tab label="Help And Support" {...a11yProps(3)} />
                                   
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                <div className="row g-3">
                                                    <h3 className='mb-3'>
                                                    Login Details
                                                    </h3>
                                                    <p>{responsedata}</p>
                                                    <div className="col-md-6">
                                                        <label htmlFor="inputName4" className="form-label">First name</label>
                                                        <input type="text" className="form-control" name="fname" id="fname" defaultValue={jesdata.firstname}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="inputname" className="form-label">Last name</label>
                                                        <input type="name" className="form-control" name="lname" id="lname" defaultValue={jesdata.lastname}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="inputEmail4" className="form-label">Email</label>
                                                        <input type="email" disabled className="form-control" id="email" name="email" defaultValue={jesdata.email}/>
                                                    </div>
                                                                    
                                                    <div className="col-md-6">
                                                        <label htmlFor="inputText" className="form-label">Mobile Number</label>
                                                        <input type="text" className="form-control" id="mobile_no" name="mobile_no" defaultValue={jesdata.mobile_no}/>
                                                    </div>
                                                    
                                                    <div className="col-12">
                                                        <label className="form-check-label me-3" htmlFor="gridCheck">
                                                            Gender
                                                        </label>
                                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                            <input type="radio" className="btn-check" name="gender" id="man" checked={selected === 'man'} onChange={handleChange1} value="man" defaultChecked autoComplete="off"  />
                                                            <label className="btn gender-btn me-2" htmlFor="man">Man</label>

                                                            <input type="radio" className="btn-check" checked={selected === 'woman'} onChange={handleChange1} name="gender" id="woman" value="woman" autoComplete="off" />
                                                            <label className="btn gender-btn" htmlFor="woman">Woman</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <button onClick={handleSubmit} type="submit" className="btn submit-btn">Save</button>
                                                    </div>
                                                    {/* <div className="col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                            <label className="form-check-label" htmlFor="gridCheck">
                                                            Send me special offers
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <h3 className='mb-3'>
                                                        Change Password
                                                        </h3>
                                                        {passworderror}
                                                        <div className="col-12">
                                                            <label htmlFor="inputName4" className="form-label">Current Password</label>
                                                            <input type="password" onChange={handleRecentassword} className="form-control" id="recent_password" />
                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="inputname"  className="form-label">New Password</label>
                                                            <input type="password"  onChange={handlePassword} className="form-control" id="paswword" name="password" />
                                                        </div>
                                                        <div className="col-12">
                                                            <label htmlFor="inputEmail4" className="form-label">Confirm Password</label>
                                                            <input type="password" className="form-control" onChange={handleCpassword} id="inputEmail4" name="cpassword"/>
                                                        </div>
                                                            <div className="col-12 text-center">
                                                                <button type="submit" onClick={changePassword}  className="btn submit-btn">Change Password</button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                     
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                        <table className="table orderTable">
                                            <thead>
                                                <tr>
                                                <th scope="col">Order No.</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Order Status	</th>
                                                <th scope="col">Order Date</th>
                                                {/* <th scope="col">Est. ship date</th>
                                                <th scope="col">Insurance</th>
                                                <th scope="col">Actions</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                (jesorder.length>0) ?
                                                jesorder.map( (jd) => (    
                                                <tr>
                                                    <th scope="row">{jd.order_id}</th>
                                                    <td>{jd.total_amount}</td>
                                                    <td>{jd.order_status}</td>
                                                    <td>{jd.order_date}</td>
                                                    {/* <td>none</td>
                                                    <td>none</td>
                                                    <td>none</td> */}
                                                </tr>
                                                 )):''}
                                            </tbody>
                                            </table>
                                            <div className="col-12">
                                        <nav className='d-flex justify-content-center'>
                                            <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={""}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            className={"pagination"}
                                            previousClassName={"page-item"}
                                            nextClassName={"page-item"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            activeLinkClassName={"active"}
                                            previousLinkClassName={"page-link"}
                                            nextLinkClassName={"page-link"}
                                        />
                                    </nav>
                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                        <div className="row g-3">
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="card">
                                                <img src="assets/img/cate-1.jpg" className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title">Fancy Diamond (Loose)</h5>
                                                    <p className="card-text item-detls">0.30 Carat Fancy Pink Purple-I1 Oval Cut Diamond</p>
                                                    <p className="card-text">SKU 14679933</p>
                                                    <div className='d-flex mt-3'>
                                                        <button href="#" className="btn view-btn">VIEW ITEM</button>
                                                        <button href="#" className="btn slecdimond-btn">SELECT THIS DIAMOND</button>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                        </div>

                                   </div>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                <div className="row my-5">
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body suport-details">
                                                    <h4> Help Line Number :</h4>
                                                     <p className='mt-4'>  
                                                     +91 12456389
                                                     </p>
                                                </div>
                                         </div>
                                    </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body suport-details">
                                                    <h4> Email :</h4>
                                                     <p className='mt-4'>  
                                                     User@gmail.com
                                                     </p>
                                                </div>
                                         </div>
                                    </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body suport-details">
                                                    <h4> Address</h4>
                                                     <p className='mt-4'>  
                                                    7-19, Aurobindo Nagar Rd
                                                     </p>
                                                </div>
                                         </div>
                                    </div>
                                </div>
                                    
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>



    </>
  )
}

export default Account