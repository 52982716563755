import React, { useState, useEffect,useRef } from 'react'
import { Link,useParams } from 'react-router-dom'
import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaTimes, FaGripLines, FaRedoAlt } from "react-icons/fa"
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import './Setting.css'
import Slider, { Range } from 'rc-slider/lib/Slider'
import GoToTop from '../parts/GoToTop'

import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';

import 'rc-slider/assets/index.css'
// import Pagination from './Pagination';
import ReactPaginate from 'react-paginate';



const style = { margin: "40px 8px" };
const style1 = { margin: "40px 8px" };
const labelStyle = { minWidth: '60px', display: 'inline-block' };
//const PATH = 'http://localhost/baemont-dev/api/server';
  const marks = {
    0: {
        style: {
            transform: "translateX(-3px)",
        },
        label: "Excellent"
    },
    33.333333: 'Very Good',
    66.6667: 'Good',
    100: {
        style: {
            right: 0,
            left: "unset",
            transform: "translateX(3px)",
        },
        label: "Fair"
    },
  };

  const dmdColor = {
    0: {
        style: {
            transform: "translateX(-3px)",
        },
        label: "M"
    },
    11.1111111: 'L',
    22.1111111: 'K',
    33.1111111: 'J',
    44.2222222: 'I',
    55.3333333: 'H',
    66.6666666: 'G',
    77.5555555: 'F',
    88.6666666: 'E',
    100: {
        style: {
            right: 0,
            left: "unset",
            transform: "translateX(3px)",
        },
        label: "D"
    },
  };




  const dmdClarity = {
    0: {
        style: {
            transform: "translateX(-3px)",
        },
        label: "I1"
    },
    11.1111111: 'SI2',
    22.1111111: 'SI1',
    33.1111111: 'VS2',
    44.2222222: 'VS1',
    55.3333333: 'VVS2',
    66.6666666: 'VVS1',
    77.5555555: 'IF',
    88.6666666: 'FL',
    100: {
        style: {
            right: 0,
            left: "unset",
            transform: "translateX(3px)",
        },
        label: "D"
    },
  };



  /*== Start ResponsiveFilter ==*/

function addClass(arr,element)
{
    if(arr.length > 0)
    {
        for(var i = 0; i <  arr.length; i++)
        {
            document.querySelector(`${element}`).classList.add(arr[i]);
        }
    }
}
function removeClass(arr, element)
{
    if(arr.length > 0)
    {
        for(var i = 0; i <  arr.length; i++)
        {
            document.querySelector(`${element}`).classList.remove(arr[i]);
        }
    }
}

  
var angle = 0;
var half_of_height = (window.innerHeight / 2);
var pop_up = document.querySelector('.pop-up');

// $(pop_up).draggable();

function btn_click()
{
    if(window.matchMedia("(max-width: 767px)").matches)
    {
        var postModalBody = document.querySelector('.popModal-body');
        //*******************//
        addClass(['filterPopMobile'],'body');
        var pop_up = document.querySelector('.pop-up');
        pop_up.classList.remove('pop-up-top-100');
        pop_up.classList.add('pop-up-bottom-0');
    }
    else
    {
        alert("your device width to high");
    }
}

function close_popup() {
    var pop_up = document.querySelector('.pop-up');
    pop_up.removeAttribute('style');
    pop_up.removeAttribute('data-x');
    pop_up.removeAttribute('data-y');
    pop_up.classList.add('pop-up-top-100');
    pop_up.classList.remove('pop-up-bottom-0');
    removeClass(['filterPopMobile'],'body');
}
  /*== End ResponsiveFilter ==*/
  /* componentDidMount() {
    fetch("https://jsonplaceholder.typicode.com/users")
    .then(res => res.json())
    .then(users => {
        this.setState({
            loading: false,
            users
        });
    });
    } */

function WeddingBands() {
    const {id} = useParams();
    const [value1, setValue1] = useState([10, 20]);
    
    const [isActive, setActive] = useState("false");
    const [isGrid, setGrid] = useState("false");
    let [isAdvance, setAdvance] = useState("false")
    let [showPop, setPop] = useState(0)

    //const [isList, setList] = useState("false");
    const wishToggle = () => {
        setActive(!isActive);
    }
    const gridToggle = () => {
        setGrid(!isGrid);
    }
    const onAdvance = () => {
        setAdvance(!isAdvance)
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    }; 
        
const shape = useRef(null);
const cut = useRef(null);
var ids;
//const ids = useRef(null);
var formData = new FormData();
let [jesdada, setJesdata] = useState([]);
let [jescnt, setJescnt] = useState([]);
let [cutrange, setCutrange] = useState([]);
let [color, setColor] = useState([]);
let [carat, setCarat] = useState([]);
let [clarity, setClarity] = useState([]);
let [polish, setPolish] = useState([]);
let [symmetry, setSymmetry] = useState([]);
let [price,setPrice] = useState([]);
let [shapes,setShapes] = useState([]);
let [rqstdata, setrequestdata] = useState();
var flag=0;
let [tabtext2, setTabtext2] = useState([]);
let [tabcss2, setTabcss2] = useState([]);
let [catres, setCatres] = useState([]);
let [category, setCategory] = useState([]);
const categories = useRef(null);
const [value, setValue] = useState(null);
const [lowerBound,SetlowerBound] = useState(0);
const [upperBound,SetupperBound] = useState(20000);
const [Value,SetValue] = useState([0,20000]);
let [tabtext1, setTabtext1] = useState([]);
let [tabcss1, setTabcss1] = useState([]);
let [metal, setMetal] = useState([]);
/* const [currentPage, setCurrentPage] = useState(1);// No of Records to be displayed on each page   
const [recordsPerPage] = useState(10);
const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
// Records to be displayed on the current page
const currentRecords = jesdada.slice(indexOfFirstRecord, indexOfLastRecord);
const nPages = Math.ceil(jesdada.length / recordsPerPage)
const pageNumbers = [...Array(nPages + 1).keys()].slice(1) */
const [offset, setOffset] = useState(0);
const [perPage] = useState(20);
const [pageCount, setPageCount] = useState(0)
const [playerData, setPlayerData] = useState([]);
const [loading, setLoading] = useState(true);
 const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage*perPage)
    setOffset(selectedPage*perPage)
  }
  var metal_ids;
  function getimgpath(id){
    var requestdata = {
        product_id: id,
    };
    var formData = new FormData();
    formData.append('json', JSON.stringify(requestdata));
    let requestOptions = {
        method: 'POST',
        body: formData // body data type must match "Content-Type" header
    };
    fetch("http://3.20.64.228/control_panel/api/server/product_image",requestOptions)
    .then((response) => response.json())
    .then((response) => {
        var imgresponse = response.data;
        document.getElementById(`img_${id}`).src = "http://3.20.64.228/control_panel/uploads/product/"+imgresponse.image;
    });
    
    
}
async function getProd(){
    var querystr = id.split("=")
    console.log(querystr[0])
    
    if (shapes.length>0) {
        flag=1;
        var requestdata = {
            categories: shapes,
        };
        formData.append('json', JSON.stringify(requestdata));
    }else if (querystr[0]=='param'){
        console.log(querystr[1])
        console.log("querystr")
        var params = querystr[1].split('&');
        console.log(params)
        flag=1;
        var requestdata = {
            categories: params[0],
        };
        formData.append('json', JSON.stringify(requestdata));

        flag=1;
        var requestdata = {
            gender: querystr[2],
        };
        formData.append('gender', JSON.stringify(requestdata));
    }

    //console.log("Polish"+polish);

    if(metal.length > 0){
        flag=1;        
        var requestdata8 = {
            metal: metal,
        };
        formData.append('json8', JSON.stringify(requestdata8));
    }else if(querystr[0]=='metal_type'){
        flag=1;        
        var requestdata8 = {
            metal: querystr[1],
        };
        formData.append('json8', JSON.stringify(requestdata8));
    } 
    if(price.length > 0){
        flag=1;        
        var requestdata2 = {
            sell_price: price,
        };
        formData.append('json2', JSON.stringify(requestdata2));
    }

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify({ title: 'React Hooks POST Request Example' })
    };
    if( flag==1){

         requestOptions = {
            method: 'POST',
            body: formData // body data type must match "Content-Type" header
        };
    } 

    var requestdatap = {
        offset: offset,
    };
    formData.append('page', JSON.stringify(requestdatap));
    requestOptions = {
        method: 'POST',
        body: formData // body data type must match "Content-Type" header
    };

    var catresponse = await fetch('http://3.20.64.228/control_panel/api/server/getsubcat_bands',requestOptions);

    var datas = await catresponse.json();
    setCatres(datas.data);

    var resp = await fetch("http://3.20.64.228/control_panel/api/server/getproduct_bands",requestOptions);
    var data = await resp.json();
    setJesdata(data.data);
    var respcnt = await fetch("http://3.20.64.228/control_panel/api/server/count_bands",requestOptions);
    var datacnt = await respcnt.json();
    setJescnt(datacnt.data);
    setPageCount(Math.ceil(jescnt/perPage))
}
useEffect(()=>{
    getProd();
    var ringdata = localStorage.getItem("ringId");
    if(ringdata!=null){
        setTabtext1("SETTING");  
        setTabtext2("DIAMOND"); 
        setTabcss1("active--bcso0"); 
        setTabcss2(""); 
    }else{
        setTabtext2("SETTING");  
        setTabtext1("DIAMOND");  
        setTabcss1(""); 
        setTabcss2("active--bcso0"); 
    }
},[shapes,metal,color,carat,clarity,polish,symmetry,price,jescnt,offset])
// 
async function putData(ev) {
    //const id = shape.current.value;
    ids = ev.target.value;
        setShapes(ids);
        getProd();
  }
  //const [isSubscribed, setIsSubscribed] = useState(false);
  async function setData(event) {    
    metal_ids = event.target.value;
    console.log(metal_ids);
    setMetal(metal_ids);
    // setPolish(selectedChkbox);
    getProd();
  }
  async function setSymmetryval(event) {    
    let selectedChkbox = [...symmetry];
    if (event.target.checked) 
        selectedChkbox.push(event.target.value);
    else
        selectedChkbox.splice(selectedChkbox.indexOf(event.target.value), 1);
    setSymmetry(selectedChkbox);
    getProd();
  }
  
  const clearPutOutput = () => {
    //setPutResult(null);
  }
  let [radioshape, setRadioshape] = useState("round")
  function shapeDetect(ev) {
    setRadioshape(ev.target.value)
    
  }
  
  const onSliderChange = (value) => {
    console.log(value)
    /* this.setState({
      value,
    }); */
    setPrice(value);
    SetValue(value);
    setCutrange(value)
    getProd();
  };
  const onSliderChange1 = (value) => {
    
    /* this.setState({
      value,
    }); */
    setColor(value)
    
    getProd();
  };
  const onSliderChange2 = (value) => {
    
    /* this.setState({
      value,
    }); */
    setCarat(value)
    
    getProd();
  };
  const onSliderChange3 = (value) => {
    
    /* this.setState({
      value,
    }); */
    setClarity(value)
    
    getProd();
  };
  const onSliderChange4 = (value) => {
    
    /* this.setState({
      value,
    }); */
    setPrice(value)
    
    getProd();
  };



    
  return (
    <>
        <div className="pop-up pop-up-top-100">
            <div className="popHead d-flex justify-content-between align-items-center">
                <button className="btn close-pop-up" onClick={close_popup}><FaTimes /> Close</button>
                <button className="btn"><FaGripLines /></button>
                <button className="btn"><FaRedoAlt /> Reset</button>
            </div>
            <div className="mobFltr popModal-body">
                { (showPop == 1) ? 
                    <div className="ring-shape hover-label filter-iconList">
                        <div className='iconList-item'>
                            <input onChange={putData} className='d-none' type="radio" name="stoneShape" id="round" defaultChecked />
                            <label htmlFor="round"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/round.png"} alt="" /><span className="text-center">Round</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input onChange={putData} ref={shape} className='d-none' type="radio" name="stoneShape" id="princess" />
                            <label htmlFor="princess"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/princess.png"} alt="" /><span className="text-center">Princess</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="cushion" />
                            <label htmlFor="cushion"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/cushion.png"} alt="" /><span className="text-center">Cushion</span></label>
                        </div>

                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="emerald" />
                            <label htmlFor="emerald"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/emerald.png"} alt="" /><span className="text-center">Emerald</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="oval" />
                            <label htmlFor="oval"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/oval.png"} alt="" /><span className="text-center">Oval</span></label>
                        </div>

                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="rediant" />
                            <label htmlFor="rediant"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/rediant.png"} alt="" /><span className="text-center">Rediant</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="asscher" />
                            <label htmlFor="asscher"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/asscher.png"} alt="" /><span className="text-center">Asscher</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="marquise" />
                            <label htmlFor="marquise"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/marquise.png"} alt="" /><span className="text-center">Marquise</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="heart" />
                            <label htmlFor="heart"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/heart.png"} alt="" /><span className="text-center">Heart</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" name="stoneShape" id="pear" />
                            <label htmlFor="pear"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/shapes/pear.png"} alt="" /><span className="text-center">Pear</span></label>
                        </div>
                    </div>
                    :
                    (showPop == 2) ? 
                    <div className="price-range w-100">
                        <div style={style}>
                            <Slider
                                range
                                allowCross={true}
                                step={33.333333}
                                pushable={33.333333}
                                marks={marks}
                                reverse={false}
                                defaultValue={[0, 100]}
                                draggableTrack
                                //onChange={log}
                            />
                        </div>
                    </div>
                    :
                    (showPop == 3) ? 
                    <div className="price-range w-100">
                        <div style={style}>
                            <Slider
                                range
                                allowCross={true}
                                step={33.333333}
                                pushable={33.333333}
                                marks={marks}
                                reverse={false}
                                defaultValue={[0, 100]}
                                draggableTrack
                                //onChange={log}
                            />
                        </div>
                    </div>
                    :
                    (showPop == 4) ? 
                    <div className="price-range w-100">
                        <div style={style}>
                            <Slider
                                range
                                allowCross={true}
                                step={33.333333}
                                pushable={33.333333}
                                marks={marks}
                                reverse={false}
                                defaultValue={[0, 100]}
                                draggableTrack
                                //onChange={log}
                            />
                        </div>
                    </div>
                    :
                    (showPop == 5) ? 
                    <div className="price-range w-100">
                        <div style={style}>
                            <Slider
                                range
                                allowCross={true}
                                step={33.333333}
                                pushable={33.333333}
                                marks={marks}
                                reverse={false}
                                defaultValue={[0, 100]}
                                draggableTrack
                                //onChange={log}
                            />
                        </div>
                    </div>
                    :
                    (showPop == 6) ? 
                    <div className="price-range w-100">
                        <div style={style}>
                            <Slider
                                range
                                allowCross={true}
                                step={33.333333}
                                pushable={33.333333}
                                marks={marks}
                                reverse={false}
                                defaultValue={[0, 100]}
                                draggableTrack
                                //onChange={log}
                            />
                        </div>
                    </div>
                    : 
                    (showPop == 7) ?
                    <h6 className='text-center'>No Content</h6>
                    : ""
                }
            </div>
            <div className="popModal-footer">
                <button className='btn btn-default w-100'>VIEW 630 Rings</button>
            </div>
        </div>
      <section className='filter-sec'>
        <div className="container">
            <div className="row">
              

                {/* <div className="col-12 text-center">
                    <div className="diamondCreation" role="group" aria-label="Earth-Created / Lab Created Diamonds">
                        <input type="radio" className="btn-check" name="dimCrtn" id="earthCreated" defaultChecked />
                        <label className="btn btn-outline-primary" htmlFor="earthCreated">Earth-Created Diamonds</label>

                        <input type="radio" className="btn-check" name="dimCrtn" id="labCreated" />
                        <label className="btn btn-outline-primary" htmlFor="labCreated">Lab-Created diamonds</label>
                    </div>
                </div> */}
            </div>
            <div className="row d-none d-md-flex">
            <div className="row d-none d-md-flex">
                <div className="col-12">
                    <div className='ringType filter-iconList'>{
                    catres.map( (jd) => (
                        <div className='iconList-item' key={jd.id}>
                            <input onChange={(e)=>{putData(e); }} ref={categories} value={jd.id} className='d-none' type="radio" name="jemsCheck" id={jd.category_name} />
                            <label htmlFor={jd.category_name}><img className='img-fluid list-img' src={window.location.origin +"/assets/img/diamond.svg"} alt="" /><span className="text-center">{jd.category_name}</span></label>
                        </div>))
                    }
                        
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <label className='me-2' htmlFor="">Metal</label>
                    <div className="ring-metal hover-label filter-iconList">
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" onChange={(e)=>{setData(e); shapeDetect(e)}}  name="stoneShape" value="18k_white_gold" id="18k_white_gold" defaultChecked />
                            <label htmlFor="18k_white_gold"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/metal/18K_White_Gold.svg"} alt="" /><span className="text-center">18K White Gold</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" value="18k_yellow_gold" onChange={(e)=>{setData(e); shapeDetect(e)}} name="stoneShape" id="18k_yellow_gold" />
                            <label htmlFor="18k_yellow_gold"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/metal/18K_Yellow_Gold.svg"} alt="" /><span className="text-center">18K Yellow Gold</span></label>
                        </div>
                        <div className='iconList-item'>
                            <input className='d-none' type="radio" value="18k_rose_gold" name="stoneShape" onChange={(e)=>{setData(e); shapeDetect(e)}} id="18k_rose_gold" />
                            <label htmlFor="18k_rose_gold"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/metal/18K_Rose_Gold.svg"} alt="" /><span className="text-center">18K Rose Gold</span></label>
                        </div>


                        <div className='iconList-item'>
                            <input className='d-none' type="radio" value="platinum" name="stoneShape" onChange={(e)=>{setData(e); shapeDetect(e)}} id="platinum" />
                            <label htmlFor="platinum"><img className='img-fluid list-img' src={window.location.origin +"/assets/img/icon/metal/Platinum.svg"} alt="" /><span className="text-center">Platinum</span></label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <label className='me-3' htmlFor="">Price</label>
                    <div className="price-range w-100">
                        <div style={style}>
                            
                            {
                            // console.log(parseInt(pricerange)+" PRICE")
                            //console.log(dftPricerange[0],dftPricerange[1])
                            /* <label style={labelStyle}>Value: </label>
                            <span>{this.state.value}</span> */}
                            <Slider
                                range
                                allowCross={true}
                                //step={33.333333}
                                //pushable={33.333333}
                                //marks={marks}
                                min={lowerBound}
                                max={upperBound}
                                reverse={false}
                                defaultValue={[lowerBound, upperBound]}
                                value = {Value}
                                draggableTrack
                                onChange={onSliderChange}
                                //onChange={log}
                            />
                            <div className='row justify-content-between mt-2'>
                                <div className="col-md-4">
                                    <input readOnly className="form-control" type="a" value={price[0]} onChange={onSliderChange} />
                                </div>
                                <div className="col-md-4">
                                    <input readOnly className="form-control" type="a" value={price[1]} onChange={onSliderChange} />
                                </div>
                            </div>
                            
                          
                        </div>
                    </div>
                </div>

            </div>
                
                
                
              
                <input type="hidden" value={price} />
               
            </div>
           
            <div className="row g-2 d-md-none">
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(1);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Shape</label>
                        <small className="text-muted">Round</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(2);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Carat</label>
                        <small className="text-muted">1 - 30</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(3);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Color</label>
                        <small className="text-muted">I - D</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(4);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Clarity</label>
                        <small className="text-muted">Sl1 - FL</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(5);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Cut</label>
                        <small className="text-muted">VG - TH</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(6);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Price</label>
                        <small className="text-muted">All</small>
                    </div>
                </div>
                <div className="col-sm-3 col-4" onClick={() => 
                            {
                                setPop(7);
                                btn_click();
                            }
                        }>
                    <div className="card card-body text-center">
                        <label htmlFor="">Lab</label>
                        <small className="text-muted">All</small>
                    </div>
                </div>

            </div>
            
            <div className="row mt-5">
                <div className="col-md-9 mx-auto text-center">
                    <h5 className="main-title-text fw-normal">
                        <span>Diamonds <b>- Viewable In 360° HD</b> That Match Your Setting</span>
                    </h5>
                    <small data-qa="description_results-gallery" className="description">
                        <p>Pick your perfect <b>diamond</b> with Baemont. Start by choosing a high-quality diamond from our selection of conflict-free diamonds. Baemont offers diamonds in a variety of diamond colors, diamond cuts, and diamond shapes. Then, select your preferred ring setting! All our diamonds are presented in high definition 360° so you know exactly what you are getting.</p>
                    </small>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                        
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 fltrPlod-list">
                    
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="allItem" data-bs-toggle="tab" data-bs-target="#allItem-pane" type="button" role="tab" aria-controls="allItem-pane" aria-selected="true">ALL Diamonds <span>({jescnt})</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="recently-view" data-bs-toggle="tab" data-bs-target="#recently-view-pane" type="button" role="tab" aria-controls="recently-view-pane" aria-selected="false">Recently Viewed</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="comparison-tab" data-bs-toggle="tab" data-bs-target="#comparison-tab-pane" type="button" role="tab" aria-controls="comparison-tab-pane" aria-selected="false">Comparison</button>
                        </li> */}
                    </ul>
                    <div className="tab-content fltr-itemList" id="">
                        <div className="tab-pane fade show active" id="allItem-pane" role="tabpanel" aria-labelledby="allItem" tabIndex="0">
                            <div className="item-views">
                                <div className="d-flex align-items-center">
                                    <span className='me-2'>Change View</span>
                                    <button className="btn btn-view me-2" onClick={gridToggle} disabled={ isGrid ? 'disabled':''}><FaTh /></button>
                                    <button className="btn btn-view" onClick={gridToggle}  disabled={ isGrid ? '':'disabled'}><FaThList /></button>
                                </div>
                            </div>
                            <div className="row itemList-cont g-2">
                                
                                { isGrid ?
                                <>
                                
                                { 
                                (jesdada.length>0) ?
                                jesdada.map( (jd) => (
                                <div className='col-md-3' key={jd.id}>
                                    <div className="card prodItem-card">
                                        <div className="card-body dimond-grid-item-body">
                                            <div className="prodImg-wrap">
                                                {getimgpath(jd.id)}
                                                <img className="img-fluid" id={'img_'+jd.id}  src={window.location.origin +"/assets/img/loading-buffering.gif"} alt="" />
                                                <div className="btnAction">
                                                    <button className="btn btn-addWishlist" onClick={wishToggle}>
                                                    { isActive ? 
                                                        <FaRegHeart />
                                                        :
                                                        <FaHeart color={'red'} />
                                                    }
                                                    </button>
                                                    <button className="btn btn-quickView"><FaSearchPlus /></button>
                                                </div>
                                            </div>
                                            <div className="prod-dtl">
                                                <div className="short-desc">
                                                    <Link to={"/product-bands/" + jd.id}>
                                                        <p className='mb-0'>{jd.product_name}</p>
                                                    </Link>
                                                </div>
                                               
                                                <div className="d-block">
                                                    <span className='fw-bold'>{jd.currency} {jd.sellprice}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )):''}
                                
                                
                                {/* <Pagination
                                nPages = { nPages }
                                currentPage = { currentPage } 
                                setCurrentPage = { setCurrentPage }
                                /> */}
                                <div className="col-12">
                                    <nav className='d-flex justify-content-center'>
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={""}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            className={"pagination"}
                                            previousClassName={"page-item"}
                                            nextClassName={"page-item"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            activeLinkClassName={"active"}
                                            previousLinkClassName={"page-link"}
                                            nextLinkClassName={"page-link"}
                                        />
                                    </nav>
                                </div>
                                
                                </>
                                :
                                <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Actual Photo</th>
                                            <th>Shape</th>
                                            <th>Carat</th>
                                            <th>Color</th>
                                            <th>Clarity</th>
                                            <th>Cut</th>
                                            <th>Lab</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { jesdada.map( (jd) => (
                                        <tr key={jd.id}>
                                            <td>
                                                <div className='grid-item-img d-flex'>
                                                <img className="img-fluid" src={window.location.origin +"/assets/img/diamond-2.jpg"} alt="" />
                                                <div className='grid-product-detail'>
                                                    {/* <Link to='/product-diamond'>Excellent Cut Round Diamond</Link> */}
                                                <ul className='list-inline'>
                                                    <li className='list-inline-item'>
                                                        <button className="btn btn-quickView"><FaSearchPlus /></button>
                                                    </li>
                                                    <li className='list-inline-item'>
                                                    <button className="btn btn-addWishlist" onClick={wishToggle}>
                                                    { isActive ? 
                                                        <FaRegHeart />
                                                        :
                                                        <FaHeart color={'red'} />
                                                    }
                                                    </button>
                                                    </li>
                                                    {/* <li className='list-inline-item'>
                                                        <button className="btn btn-quickView"><FaSearchPlus /></button>
                                                    </li> */}
                                                    
                                                </ul>
                                                <div className="d-block"><span className="fw-bold">RM 7,000</span></div>
                                                
                                                </div>
                                                
                                                </div>
                                            </td>
                                            <td>{jd.shape}</td>
                                            <td>{jd.carat}</td>
                                            <td>{jd.color}</td>
                                            <td>{jd.clarity}</td>
                                            <td>{jd.cut}</td>
                                            <td>{jd.lab_name}</td>
                                          
                                        </tr>
                                        ))}
                                    </tbody>
                                    
                                </table>
                                </div>
                                }
                                

                            </div>
                        </div>
                        <div className="tab-pane fade" id="recently-view-pane" role="tabpanel" aria-labelledby="recently-view" tabIndex="0">
                            <div className="item-views">
                                <div className="d-flex align-items-center">
                                    <span className='me-2'>Change View</span>
                                    <button className="btn border border-1 lh-1 me-2"><FaTh /></button>
                                    <button className="btn border border-1 lh-1"><FaThList /></button>
                                </div>
                            </div>
                            <div className="row itemList-cont g-2">
                                <div className="col-md-4 m-auto text-center">
                                    <h3 className='text-muted'>Recently no view</h3>
                                </div>
                            </div>
                            
                        </div>
                        {/* <div className="tab-pane fade" id="comparison-tab-pane" role="tabpanel" aria-labelledby="comparison-tab" tabIndex="0">3</div> */}
                    </div>

                </div>
            </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default WeddingBands