import React from 'react'

function PrivacyPolicy() {
  return (
    <div>
      
  <section id="breadcrumbRow">
    <h2>Privacy Policy</h2>
       <div className="container">
    <div className="row pageTitle m0">
      <div className="col-12">
          <h4 className="fleft">Privacy Policy</h4>
          <ul className="breadcrumb fright">
             <li><a href="index.html">home</a></li>
             <li className="active">Privacy Policy</li>
          </ul>

      </div>
       </div>
    </div>
 </section>

 <section id="contactRow" className=" contentRowPad">
    <div className="container">
        <div className="row">
          <div className='col-12'>
            
<p><strong><em>Last Updated: 18th January 2022</em></strong></p>
<ol className='pri-pol-text'>
    <li>At BAEMONT, we take your privacy seriously. We are committed to complying with all data protection/privacy laws
        which are applicable to us.</li>
    <li>In the course of making purchase at <a href="http://www.baemont.com">baemont.com</a>, we will be collecting,
        using, disclosing and/or processing data, including your personal data.</li>
    <li>This Privacy Policy exists to keep you in the know about how we collect, use, disclose and/or process the data
        we collect and receive during the course of making purchase at our website. We will only collect, use, disclose
        and/or process your personal data in accordance with this Privacy Policy.</li>
    <li>It is important that you read this Privacy Policy together with any other applicable notices we may provide on
        specific occasions when we are collecting, using, disclosing and/or processing personal data about you, so that
        you are fully aware of how and why we are using your personal data.</li>
    <li>BY CLICKING OR CHECKING “SIGN UP”, “I AGREE TO BAEMONT’S PRIVACY POLICY” OR SIMILAR STATEMENTS AVAILABLE AT THE
        BAEMONT REGISTRATION PAGE OR CHECKOUT PAGE, YOU ACKNOWLEDGE THAT YOU HAVE BEEN NOTIFIED OF AND UNDERSTOOD THE
        TERMS OF THIS PRIVACY POLICY AND THAT YOU HAVE AGREED AND CONSENTED TO THE COLLECTION, USE, DICLOSURE AND/OR
        PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED AND UNDER THE TERMS HEREIN.</li>
    <li>We may update this Privacy Policy from time to time. Any changes we make to this Privacy Policy in the future
        will be posted on this page and, where appropriate, notified to you, whereupon your continued use of the
        website, including placing orders, shall constitute your acknowledgment and acceptance of the changes we make to
        this Privacy Policy, as notified to you. Please check back frequently to see any updates or changes to this
        Privacy Policy.</li>
    <li>This Privacy Policy applies in conjunction with other notices, contractual clauses and consent clauses that
        apply in relation to the collection, use, disclosure and/or processing of your personal data by us, and is not
        intended to override them unless we state expressly otherwise.</li>
    <li>You can visit the <a href="http://www.baemont.com">baemont.com</a> and browse without having to provide personal
        details. However, you will be required to sign up for an account if you wish to make purchase.</li>
    <li>If you have any comments, suggestions or complaints in relation to your personal data, please contact our Data
        Protection Officer through our&nbsp;<a href="http://baemont.com/contactus" target="_blank"
            rel="noopener">Contact Us</a>&nbsp;page.</li>
</ol>
<p><strong>The Personal Data We Collect From You</strong></p>
<ol className='pri-pol-text'>
    <li>Personal Data means any information, whether recorded in a material form or not, from which the identity of an
        individual is apparent or can be reasonably and directly ascertained by the entity holding the information, or
        when put together with other information would directly and certainly identify an individual.</li>
    <li>During the course of your use of <a href="http://www.baemont.com">baemont.com</a>, we may collect personal data
        from you, which includes but is not limited to:<br/>
        <p>(a) <strong>Identity data</strong>, such as your name, gender, profile picture, and date of birth;</p>
        <p>(b) <strong>Contact data</strong>, such as billing address, delivery address, email address and phone
            numbers;</p>
        <p>(c) <strong>Account data</strong>, such as bank account and payment details;</p>
        <p>(d) <strong>Transaction data</strong>, such as details about payments to and from you, and other details of
            products you have purchased from us;</p>
        <p>(e) <strong>Technical data</strong>, such as internet protocol (IP) address, your login data, browser type
            and version, time zone setting and location, browser plug-in types and versions, operating system and
            platform, and other technology on the devices you use to access the website;</p>
        <p>(f) <strong>Profile data</strong>, such as your username and password, purchases or orders made by you, your
            interests, preferences, feedback and survey responses;</p>
        <p>(g) <strong>Usage data</strong>, such as information on how you use the website, products and services or
            view any content on the website;</p>
        <p>(h) <strong>Location data</strong>, such as when you capture and share your location with us in the form of
            photographs or videos and upload such content to the website;</p>
        <p><span>(i) </span><strong>Marketing and communications
                data</strong><span>, such as your preferences in receiving marketing from us
                and our third parties and your communication preferences.</span></p>
    </li>
    <li>During the course of your use of the website, we may receive personal data from you in the following
        situations:<br/>
        <p>(a) When you create an account with us;</p>
        <p>(b) When you purchase any products available on the website;</p>
        <p>(c) When you use any of the features or functions available on the website, including the recording of any
            user-generated content to be uploaded on the website;</p>
        <p>(d) When you subscribe to our publications or marketing collaterals;</p>
        <p>(e) When you enter a competition, promotion or survey;</p>
        <p>(f) When you participate in any activity or campaign on the website;</p>
        <p>(g) When you log in to your account on the website or otherwise interact with us via an external service or
            application, such as Facebook or Google; and</p>
        <p>(h) When you interact with us offline, including when you interact with our customer service agents.</p>
    </li>
    <li>During the course of your use of the website, we may also receive your personal data from third parties for the
        purposes set out in this Privacy Policy or any other terms.</li>
    <li>You must only submit personal data which is accurate and not misleading and you must keep it up to date and
        inform us of any changes to the personal data you have provided to us. We shall have the right to request for
        documentation to verify the personal data provided by you as part of our customer verification processes.</li>
    <li>We will only be able to collect your personal data if you voluntarily submit the personal data to us or as
        otherwise provided for under this Privacy Policy. Unfortunately, if you choose not to submit your personal data
        to us or subsequently withdraw your consent to our use of your personal data, you may not access the website or
        make any purchase at the website.</li>
    <li>You may access and update your personal information submitted to us at any time as described below.</li>
    <li>If you provide personal data of any third party to us, you represent and warrant that you have obtained the
        necessary consent from that third party to share and transfer his/her personal data to us, and for us to
        collect, use and disclose that data in accordance with this Privacy Policy.</li>
    <li>If you sign up to be a user on our website using your social media account or use certain other BAEMONT social
        media features, we may access personal data about you which you have voluntarily provided to your social media
        provider in accordance with the provider’s policies and we will manage your personal data in accordance with
        this Privacy Policy.</li>
</ol>
<p><strong>Use and Disclosure of Personal Data</strong></p>
<ol className='pri-pol-text'>
    <li>The personal data we collect from you or via third parties may be used by us, or shared with or transferred to
        third parties (including related companies, third party service providers and their service providers and
        related companies, third party sellers, and companies located both inside and outside your home country), for
        certain purposes, which include but are not limited to the following:<br/>
        <p>(a) To facilitate your use of the website, including responding to your queries,
            feedback, claims or disputes through our customer service agents;</p>
        <p>(b) To process orders you submit through the website;</p>
        <p>(c) Payments that you make through the website for products, whether sold by us or
            a third party seller, will be processed by our agent;</p>
        <p>(d) To deliver the products you have purchased through the website, whether sold by
            us or a third party seller. We may pass your personal information on to a third party in order to make
            delivery of the product to you (for example to our courier or supplier), whether the product is sold through
            the website by us or a third party seller;</p>
        <p>(e) To update you on the delivery of the products, whether sold through the website by us or a third party
            seller, and for customer support purposes;</p>
        <p>(f) To compare information, and verify with third parties in order to ensure that the information is
            accurate;</p>
        <p>(g) To administer your account (if any) with us;</p>
        <p>(h) To verify and carry out financial transactions in relation to payments you make online;</p>
        <p>(i) To audit the downloading of data from the website;</p>
        <p>(j) To improve the layout or content of the pages of the website and customise them for users;</p>
        <p>(k) To identify visitors on the website;</p>
        <p>(l) To carry out research on our users’ demographics and behaviour;</p>
        <p>(m) To provide you with information we think you may find useful or which you have requested from us,
            including information about our or third party sellers’ products and services, provided you have indicated
            that you have not objected to being contacted for these purposes;</p>
        <p>(n) To promote the website and use information that you give to us, such as user-generated content (including
            video content) that you can choose to broadcast on our website, as part of our advertising and marketing
            campaigns to promote the website;</p>
        <p>(o) To process any complaints, feedback, enforcement action and take-down requests in relation to any content
            you have uploaded to the website;</p>
        <p>(p) To derive further attributes relating to you based on personal data provided by you (whether to us or
            third parties), in order to provide you with more targeted and/or relevant information;</p>
        <p>(q) We may also use your personal information to send you marketing or promotional materials about our or
            third-party sellers’ products and services from time to time, unless you choose to opt-out from receiving
            these materials; and</p>
        <p>(r) We may also conduct automated-decision making processes in accordance with any of these purposes.</p>
    </li>
    <li>For the avoidance of doubt, you acknowledge and consent to BAEMONT sharing anonymised information such as but
        not limited to in the following circumstances:<br/>
        <p>(a) Aggregated information. We may conduct joint data analytics projects with selected third-party providers
            using anonymised information to predict user interests and provide users with more targeted and/or relevant
            information based on aggregated information about that user’s activities outside the website;</p>
        <p>(b) Behavioural-based advertising. We may collaborate with selected third parties using anonymised
            information to derive certain models that would facilitate more accurate advertising to selected users.</p>
    </li>
    <li>You may unsubscribe from receiving marketing information at any time by using the unsubscribe function within
        the electronic marketing material. We may use your contact information to send newsletters from us and from our
        related companies.</li>
    <li>In exceptional circumstances, we may be required to disclose personal information, such as when there are
        grounds to believe that the disclosure is necessary to prevent a threat to life or health, or for law
        enforcement purposes, or for fulfilment of legal and regulatory requirements and requests.</li>
    <li>We may share and permit the sharing of your personal data with third parties and our affiliates for any of the
        abovementioned purposes, including but not limited to, facilitating your use of the website, completing a
        transaction with you, managing your account and our relationship with you, marketing and fulfilling any legal or
        regulatory requirements and requests as deemed necessary by us. In sharing your personal data with them, we
        endeavour to ensure that the third parties and our affiliates keep your personal data secure from unauthorised
        access, collection, use, disclosure, processing or similar risks and retain your personal data only for as long
        as they need your personal data to achieve the abovementioned purposes.</li>
    <li>If you are located in Malaysia, we may transfer or permit the transfer of your personal data outside of Malaysia
        for any of the purposes set out in this Privacy Policy.</li>
</ol>
<p><strong>Withdrawal of Consent to Continued Use, Disclosure and/or Processing of Personal Data </strong></p>
<ol className='pri-pol-text'>
    <li>You may communicate the withdrawal of your consent to the continued use, disclosure and/or processing of your
        personal data including personal data relating to others who may be identified from that personal data for any
        of the purposes and in the manner as stated above at any time, by contacting our Data Protection Officer through
        the Contact Us page.</li>
    <li>Please note that if you communicate your withdrawal of your consent to our use, disclosure or processing of your
        personal data for the purposes and in the manner as stated above, we may not be in a position to continue to
        provide the services at our website to you or perform on any contract we have with you, and we will not be
        liable in the event that we do not continue to provide the services to, or perform our contract with you. Our
        legal rights and remedies are expressly reserved in such an event.</li>
</ol>
<p><strong>Updating Your Personal Data</strong></p>
<ol className='pri-pol-text'>
    <li>It is important that the personal data you provide to us is accurate and complete for you to continue using the
        website. You are responsible for informing us of changes to your personal data, or in the event you believe that
        the personal data we have about you is inaccurate, incomplete, misleading or out of date.</li>
    <li>You can update your personal data anytime by accessing your account on the Platform. If you are unable to update
        your personal data through your account, you can do so by contacting our Data Protection Officer through our
        Contact Us page.</li>
    <li>We take steps to share the updates to your personal data with third parties and our affiliates with whom we have
        shared your personal data if your personal data is still necessary for the above-stated purposes.</li>
</ol>
<p><strong>Accessing Your Personal Data</strong></p>
<ol className='pri-pol-text'>
    <li>If you would like to request information about your personal data which we have collected, or enquire about the
        ways in which your personal data may have been used, disclosed or processed by us within the past year, please
        contact our Data Protection Officer through our Contact Us page. In order to facilitate processing of your
        request, it may be necessary for us to request further information relating to your request.</li>
    <li>We reserve the right to charge a reasonable administrative fee for retrieving your personal data records. If so,
        we will inform you of the fee before processing your request.</li>
    <li>We will respond to your request as soon as reasonably possible. If we are unable to respond to your request
        within twenty-one (21) days from the date of your request, we will inform you in writing. If we are unable to
        provide you with any personal data or to make a correction requested by you, we shall generally inform you of
        the reasons why we are unable to do so (except where we are not required to do so under the applicable data
        protection laws).</li>
</ol>
<p><strong>Security of Your Personal Data</strong></p>
<ol className='pri-pol-text'>
    <li>To safeguard your personal data from unauthorised access, collection, use, disclosure, processing, copying,
        modification, disposal, loss, misuse, modification or similar risks, we have introduced appropriate
        administrative, physical and technical measures such as:<br/>
        <p>(a) Restricting access to personal data to individuals who require access;</p>
        <p>(b) Maintaining technology products to prevent unauthorised computer access; and</p>
        <p>(c) Using 128-bit SSL (secure sockets layer) encryption technology when processing your financial details.
        </p>
    </li>
    <li>If you believe that your privacy has been breached by BAEMONT, please contact our Data Protection Officer
        through our Contact Us page.</li>
    <li>You should be aware, however, that no method of transmission over the internet or method of electronic storage
        is completely secure. While security cannot be guaranteed, we strive to protect the security of your information
        and are constantly reviewing and enhancing our information security measures.</li>
    <li>Your password is the key to your account. Please use unique numbers, letters and special characters, and do not
        share your BAEMONT password to anyone. If you do share your password with others, you will be responsible for
        all actions taken in the name of your account and the consequences. If you lose control of your password, you
        may lose substantial control over your personal data and other data submitted to BAEMONT. You could also be
        subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for
        any reason or if you have grounds to believe that your password has been compromised, you should immediately
        Contact Us and change your password. You are reminded to log out of your account and close the browser when you
        are finished with using a shared computer.</li>
</ol>
<p><strong>Retention of Personal Data</strong></p>
<ol className='pri-pol-text'>
    <li>We will only retain your personal data for as long as we are either required to by law or as is relevant for the
        purposes for which it was collected.</li>
    <li>We will cease to retain your personal data, or remove the means by which the data can be associated with you, as
        soon as it is reasonable to assume that such retention no longer serves the purposes for which the personal data
        was collected, and is no longer necessary for any legal or business purpose.</li>
</ol>
<p><strong>Children and Minors Under 18 Years of Age</strong></p>
<ol className='pri-pol-text'>
    <li>BAEMONT does not sell products for purchase by children under 18 years of age, nor does it intend to provide any
        of the services of the website to children under 18 years of age. We do not knowingly collect any personal data
        relating to children under 18 years of age.</li>
    <li>You hereby confirm and warrant that you are currently 18 years old or above and you are capable of understanding
        and accepting the terms of this Privacy Policy. If you are under 18 years old, you may use our website only with
        the involvement of a parent or guardian.</li>
    <li>As a parent or legal guardian, please do not allow minors under your care to submit personal data to BAEMONT. In
        the event that such personal data of a minor is disclosed to BAEMONT, you hereby consent to the processing of
        the minor’s personal data and accept and agree to be bound by this Privacy Policy and take responsibility for
        his or her actions.</li>
    <li>We will not be responsible for any unauthorised use of the website by yourself, users who act on your behalf or
        any unauthorised users. It is your responsibility to make your own informed decisions about the use of the
        website and take necessary steps to prevent any misuse of the website.</li>
</ol>
<p><span>Request
        Erasure of Your Personal Data</span><strong><br/></strong></p>
<ol className='pri-pol-text'>
    <li>You have the right to ask us to delete or remove personal data
        when there is no good reason for us to continue processing it. If&nbsp; there is a need to do so, please reach
        out to our Personal Data Officer at hello@baemont.com.</li>
</ol>
<p><strong>Collection of Computer Data</strong></p>
<ol className='pri-pol-text'>
    <li>We or our authorised service providers may use cookies, web beacons, and other similar technologies for storing
        data to help provide you with a better, faster, safer and personalised experience when you use the website.</li>
    <li>When you visit the website through your computer, mobile device, or any other device with internet connectivity,
        our company servers will automatically record data that your browser sends whenever you visit a website. This
        data may include:<p>(a) Your computer or device’s IP address;</p>
        <p>(b) Browser type;</p>
        <p>(c) Webpage you were visiting before you came to the website;</p>
        <p>(d) The pages within the website which you visit; and</p>
        <p>(e) The time spent on those pages, items and data searched for on the website, access times and dates, and
            other statistics.</p>
    </li>
    <li>This data is collected for analysis and evaluation in order to help us improve our website and the services and
        products we provide.</li>
    <li>Cookies are small text files (typically made up of letters and numbers) placed in the memory of your browser or
        device when you visit a website or view a message. They allow us to recognize a particular device or browser and
        help us to personalise the content to match your preferred interests more quickly, and to make the website more
        convenient and useful to you.</li>
    <li>You may be able to manage and delete cookies through your browser or device settings. For more information on
        how to do so, visit the help material of your browser or device.</li>
    <li>Web beacons are small graphic images that may be included on our website. They allow us to count users who have
        viewed these pages so that we can better understand your preference and interests.</li>
</ol>
<p><strong>BAEMONT’s Right to Disclose Personal Data</strong></p>
<p>YOU ACKNOWLEDGE AND AGREE THAT BAEMONT HAS THE RIGHT TO DISCLOSE YOUR PERSONAL DATA TO ANY LEGAL, REGULATORY,
    GOVERNMENTAL, TAX, LAW ENFORCEMENT OR OTHER AUTHORITIES OR THE RELEVANT RIGHT OWNERS, IF BAEMONT HAS REASONABLE
    GROUNDS TO BELIEVE THAT DISCLOSURE OF YOUR PERSONAL DATA IS NECESSARY FOR THE PURPOSE OF MEETING ANY OBLIGATIONS,
    REQUIREMENTS OR ARRANGEMENTS, WHETHER VOLUNTARY OR MANDATORY, AS A RESULT OF COOPERATING WITH AN ORDER, AN
    INVESTIGATION AND/OR A REQUEST OF ANY NATURE BY SUCH PARTIES. TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, YOU AGREE
    NOT TO TAKE ANY ACTION AND/OR WAIVE YOUR RIGHTS TO TAKE ANY ACTION AGAINST BAEMONT FOR THE DISCLOSURE OF YOUR
    PERSONAL DATA IN THESE CIRCUMSTANCES.</p>
<p><strong>Third Party Sites</strong></p>
<ol className='pri-pol-text'>
    <li>The website may contain links to other websites operated by other parties, such as our business affiliates or
        payment gateways. We are not responsible for the privacy practices of websites operated by these other parties.
        You are advised to check on the applicable privacy policies of those websites to determine how they will handle
        any information they collect from you.</li>
</ol>
<p></p>
          </div>
        </div>
        </div>
        </section>





    </div>
  )
}

export default PrivacyPolicy