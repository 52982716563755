import React, { Component } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'



//function CustomDesign() {
class CustomDesign extends Component {
      constructor(props) {
        super(props);
        this.state = {
          files: [],
        };
      }
      handleChange(files) {
        this.setState({
          files: files,
        });
      }
    render() {
        return (
            <>
                <section id="breadcrumbRow">
                    <h2>Custom Design</h2>
                    <div className="container">
                        <div className="row pageTitle m0">
                            <div className="col-12">
                                <h4 className="fleft">Custom Design</h4>
                                <ul className="breadcrumb fright">
                                    <li><a href="index.html">Engagement Rings</a></li>
                                    <li className="active">Custom Design</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="" className="contentRowPad">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>
                                <h3 className="display-6 fs-3 text-center">Submit Your Customize Design</h3>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <div className="row g-3">
                                    <div className="col-12">
                                        <label htmlFor="uName" className="">Your Name</label>
                                        <input type="text" className="form-control" id="uName" value="" />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="uEMail" className="">Email</label>
                                        <input type="text" className="form-control" id="uEMail" value="" />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="uPhone" className="">Phone</label>
                                        <input type="phone" className="form-control" id="uPhone" placeholder="Phone No." />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="uRemarks" className="">Remarks</label>
                                        <textarea id='uRemarks' className='form-control'></textarea>
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-default mb-3">Submit Request</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <DropzoneArea 
                                    onChange={this.handleChange.bind(this)} 
                                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                                />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default CustomDesign