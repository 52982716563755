import React from 'react'

function About() {
  return (
    <div>
      
  <section id="breadcrumbRow">
    <h2>About us</h2>
       <div className="container">
    <div className="row pageTitle m0">
      <div className="col-12">
          <h4 className="fleft">About us</h4>
          <ul className="breadcrumb fright">
             <li><a href="index.html">home</a></li>
             <li className="active">About us</li>
          </ul>

      </div>
       </div>
    </div>
 </section>

 <section id="whatWeDid" className="contentRowPad">
    <div className="container">
       <div className="row">
          <div className="col-sm-6 tab_menu">
             <div className="row m0">
             </div>
          </div>
          <div className="col-sm-6 tab-contents">
             <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                   <h3>What we did in 2018</h3>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                </div>
                <div role="tabpanel" className="tab-pane" id="wwd2017">
                   <h3>What we did in 2017</h3>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                </div>
                <div role="tabpanel" className="tab-pane" id="wwd2016">
                   <h3>What we did in 2016</h3>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                </div>
                <div role="tabpanel" className="tab-pane" id="wwd2015">
                   <h3>What we did in 2015</h3>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                   <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.to help our lovely customers.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <section id="hww" className="contentRowPad planing-sec">
    <div className="container">
      <div className="row">
       <h3>how we work</h3>
       <div className="col-md-4">
          <div className="card card-body">
            <h5><span>01.</span> Planning</h5>
            <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
          </div>
       </div>
       <div className="col-md-4">
          <div className="card card-body">
            <h5><span>02.</span> Designing</h5>
            <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
          </div>
       </div>
       <div className="col-md-4">
          <div className="card card-body">
            <h5><span>03.</span> Shipping</h5>
            <p>We provide the best Quality of products to you.We are always here to help our lovely customers.We ship our products anywhere with more secure.</p>
          </div>
       </div>
    </div>
  </div>
 </section>


    </div>
  )
}

export default About