import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import CartSummary from './CartSummary'

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js';
//import {PaymentElement} from '@stripe/react-stripe-js';

import $ from 'jquery'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 70,
    height: 40,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          right: '10px',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#8b2845',
          },
        },
        '&:not(.Mui-checked)': {
          filter: 'drop-shadow(0 0 1px #000)',
        },
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#8b2845',
        borderRadius: 22 / 1,
        width: '100%',
        '&:before, &:after': {
          //content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: '11px',
          fontWeight: 'bold',
          width: 25,
          height: 16,
        },
        '&:before': {
          content: '"ON"',
          color: '#fff',
          textAlign: 'left',
          left: 13,
        },
        '&:after': {
          content: '"OFF"',
          color: '#fff',
          textAlign: 'right',
          right: 11,
        },
      },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#001e3c' : '#ffffff',
    boxShadow: 'none',
    //opacity: 0,
    width: 22,
    height: 22,
    margin: 0,
    marginLeft:0,
  },
}));
//const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
const stripePromise = loadStripe("pk_test_51MI7DASFfYsJEFuespsuJsbbUeFzEMTG9m2nRk0ux0LVouYwGc69ELCln0wrTLKHexAktryGpK5n4iAIfe9e0yh600Z0pWPa2M");
const CheckoutForm = () => {
  
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://3.20.64.228/control_panel/api/payment/order_status/",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
  
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};




function Checkout() {
  const stripePromise = loadStripe("pk_test_51MI7DASFfYsJEFuespsuJsbbUeFzEMTG9m2nRk0ux0LVouYwGc69ELCln0wrTLKHexAktryGpK5n4iAIfe9e0yh600Z0pWPa2M");
  var customer_id = localStorage.getItem("customer_id");
  if(customer_id==null){
      window.location.href = window.location.origin + '/login';
  }
  var preemail = localStorage.getItem("email");
  const [emailCheckout, setEmailCheckout] = useState();

  const [finalemail,setFinalemail] = useState();
  const [billadd1,setBilladd1] = useState();
  const [billadd2,setBilladd2] = useState();
  const [billcity,setBillcity] = useState();
  const [shipadd1,setShipadd1] = useState();
  const [shipadd2,setShipadd2] = useState();
  const [shipcity,setShipcity] = useState();
  const [customer,setCustomer] = useState();
  const [customername,setCustomername] = useState();
  const [deliveryguide,setDeliveryguide] = useState();
  const[couponval,setCouponval] = useState();
  //const[clientsecret,setClientsecret] = useState();
  const [clientSecret, setClientSecret] = useState("");
  //const[taxnval,setTaxnval] = useState();
  let [disable, setDisable] = useState(false);
  const [disableemail, setDisableEmail] = useState(true);
  let [billAdd, setBillAdd] = useState(false);
  let [checkout, setCheckout] = useState(false);
  var emailval = '';
  function checkBlank(){
    emailval = $('#uEmail').val();
   
    if(emailval.length > 0)
    {
      $('#checkout_email').val(emailval);
          setFinalemail(emailval)
          setDisable(true)
          setDisableEmail(false)
    }
    else
    {
      $('#uEmail').focus();
    }
    setCustomer(customer_id)
    setCustomername(localStorage.getItem("firstname")+' '+localStorage.getItem("lastname"))
}

    var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));
    var total_price = 0;
    
    var ids=[] ;
    var product_type = [];
    for(var i = 0; i <  existing_cart.length; i++)
    {
        if(existing_cart[i]['product_type']=='compound'){
            
            ids[i]=existing_cart[i]['ring']['id']+'#'+existing_cart[i]['diamond']['id']+'@'+existing_cart[i]['ring_size']+'ringtext'+existing_cart[i]['ring_text']+'styles'+existing_cart[i]['ring_font'];
            product_type[i] ="compound"; 
        }  else{
            ids[i] = existing_cart[i]['finejewellery'][0]['id'] +"#" +existing_cart[i]['finejewellery'][0]['qty']+'@'+existing_cart[i]['ring_size']+'ringtext'+existing_cart[i]['ring_text']+'styles'+existing_cart[i]['ring_font'];;
            product_type[i] ="simple"; 
        }
    }
    var formData = new FormData();
    formData.append("params",JSON.stringify(ids));
    formData.append("product_type",product_type);
    useEffect(() => {
      // Create PaymentIntent as soon as the page loads
       
    }, []);
  
    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };
    
      const getintent = () => {
        formData.append("billiaddress1",$('#billi_address1').val());
        formData.append("billiaddress2",$('#billi_address2').val());
        formData.append("billicity",$('#bill_city').val());



        formData.append("customer_id",customer_id);
        formData.append("email",preemail);
        formData.append("customername",localStorage.getItem("firstname")+' '+localStorage.getItem("lastname"));
        formData.append("instructions",$('#instruction').val());
        formData.append("coupon_code",couponval);
          

        fetch("http://3.20.64.228/control_panel/api/payment/createnew", {
    
        method: "POST",
        
        body: formData,
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
    }

  /* const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setDisable(!disable);
  }; */

  //const showshipping = () => {
  function showshipping() {
      setBillAdd(!billAdd)
  }
  function handleChange(){
    var billi_address1 = $('#billi_address1').val();
    var billi_address2 = $('#billi_address2').val();
    var billi_city = $('#bill_city').val();

    var ship_address1 = $('#ship_address1').val();
    var ship_address2 = $('#ship_address2').val();
    var ship_city = $('#ship_city').val();
    var instruction = $('#instruction').val();

    setBilladd1(billi_address1)
    setBilladd2(billi_address2)
    setBillcity(billi_city)

    setShipadd1(ship_address1)
    setShipadd2(ship_address2)
    setShipcity(ship_city)
    setDeliveryguide(instruction)

    setDisable(!disable)
    setCheckout(true)
    getintent();
  }
  return (
    <div>


      <section className="bg-light py-2">
        <div className="container">
          <div className="row pageTitle m0">
            <div className="col-12">
              <Link className="theme-color text-uppercase mb-2" to="/cart"><small className="d-flex align-items-center"><FaAngleLeft />&nbsp; Back to cart</small></Link>
              <h4 className="text-uppercase mb-0">SECURE CHECKOUT</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="cartList py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-8">
              <div className="row g-4">
                  <div className="col-12">
                      <div className="card shadow-default border-0">
                          <div className="card-body">
                            <h5 className='text-uppercase text-muted mb-2'>Contact information</h5>
                            {disableemail?
                              <>
                                  
                                  <p>Please provide an email address to receive your order confirmation email.</p>
                                  <div className="mb-3">
                                      <label htmlFor="uEmail" className="form-label">Email address</label>
                                      <input type="email" className="form-control" id="uEmail" name="uEmail" defaultValue={preemail} placeholder="name@example.com" onChange={(ev)=>{ setEmailCheckout(ev.target.value) }} />
                                  </div>
                                  <FormControlLabel className='' control={<Checkbox defaultChecked />} label="Sign up for special offers" />
                                  <small className="text-muted d-block mt-3 mb-4">* By signing up you confirm that you have read the <Link target="_blank" to="/privacy-policy/">Privacy Policy</Link> and agree that your email and the provided information will be collected and used by Baemont for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing or contacting us via <Link data-ajax-mode="disabled" to="mailto:service@baemont.com">service@baemont.com</Link>.</small>
                                  <div className="d-flex justify-content-end">
                                    <Button className='btn btn-outline-default' onClick={checkBlank}>Continue</Button>
                                  </div>
                                  <hr />
                                  {/* <div className="d-flex justify-content-center mt-3">
                                    <b className='me-2'>OR</b> <Link to="/login">Create an account</Link>
                                  </div> */}


                              </>:''
                            }
                          </div>
                          
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="card shadow-default border-0">
                          <div className="card-body">
                              <h5 className='text-uppercase text-muted'>Shipping & Billing</h5>
                              { disable? 
                              <>
                              <h6 className="sub-title-block mt-5">DELIVERY ADDRESS</h6>
                              <p>Please provide an address where someone over 18 or older will be able to accept and sign for the delivery.</p>
                              <p><b>Unfortunately, we are unable to ship to P.O. boxes.</b></p>

                              <div className="row g-3">
                                {/* <div className="col-md-6">
                                  <label htmlFor="inputEmail4" className="form-label">Email</label>
                                  <input type="email" className="form-control" id="inputEmail4" />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="inputPassword4" className="form-label">Password</label>
                                  <input type="password" className="form-control" id="inputPassword4" />
                                </div> */}
                                <div className="col-12">
                                  <label htmlFor="inputAddress" className="form-label">Customer Name</label>
                                  <input type="text" className="form-control" id="billing_customer_name" defaultValue={customername} name="billing_customer_name"  placeholder="" />
                                </div>
                                <div className="col-12">
                                  <label htmlFor="inputAddress" className="form-label">Address</label>
                                  <input type="text" className="form-control" id="billi_address1" name="billi_address1"  placeholder="1234 Main St" />
                                </div>
                                <div className="col-12">
                                  <label htmlFor="inputAddress2" className="form-label">Address 2</label>
                                  <input type="text" className="form-control" id="billi_address2" name="billi_address2" placeholder="Apartment, studio, or floor" />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="inputCity" className="form-label">City</label>
                                  <input type="text" className="form-control" id="bill_city" name="bill_city"  />
                                </div>
                              </div>
                              
                              
                              <ul className="list-group list-group-flush border border-start-0 border-end-0 my-5">
                                <li className="list-group-item">
                                  <FormControlLabel
                                    className='ms-0 justify-content-between w-100'
                                    control={<Android12Switch defaultChecked />}
                                    defaultValue="0"
                                    labelPlacement="start"
                                    label="Include price on receipt"
                                  />
                                </li>
                                <li className="list-group-item">
                                  <FormControlLabel
                                    className='ms-0 justify-content-between w-100'
                                    control={<Android12Switch checked={billAdd} onChange={showshipping} />}
                                    //defaultValue="0"
                                    labelPlacement="start"
                                    label="Use same address for billing"
                                    //defaultChecked
                                    
                                  />
                                </li>
                              </ul>
                              {billAdd ?
                              (
                                <>
                                <div className="row g-3 my-auto">
                                  {/* <div className="col-md-6">
                                    <label htmlFor="inputEmail4" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="inputEmail4" />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="inputPassword4" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="inputPassword4" />
                                  </div> */}
                                  {/* <div className="col-12">
                                    <label htmlFor="inputAddress" className="form-label">Customer Name</label>
                                    <input type="text" className="form-control" id="shipping_customer_name" defaultValue={customername} name="shipping_customer_name"  placeholder="" />
                                  </div> */}
                                  <div className="col-12">
                                    <label htmlFor="inputAddress" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="ship_address1" name="ship_address1" placeholder="1234 Main St" />
                                  </div>
                                  <div className="col-12">
                                    <label htmlFor="inputAddress2" className="form-label">Address 2</label>
                                    <input type="text" className="form-control" id="ship_address2" name='ship_address2' placeholder="Apartment, studio, or floor" />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="inputCity" className="form-label">City</label>
                                    <input type="text" className="form-control" id="ship_city" name="ship_city"  />
                                  </div>
                                </div>
                                
                                </>
                              )
                              :
                              ''}
                              <details className='mb-3'>
                                <summary className='mb-2'><small className='text-muted ms-2'>Have a Special Instructions?</small></summary>
                                <textarea name='instruction' id='instruction' className='form-control' rows="4"></textarea>
                              </details>
                              <div className="d-flex justify-content-end">
                                <Button className='btn btn-outline-default' onClick={handleChange}>Continue</Button>
                              </div>
                              </>
                              :
                              ''
                              }
                          </div>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="card shadow-default border-0 user-payments">
                          <div className="card-body">
                            <div id="payCard" className="row d-flex justify-content-between">
                              <h5 className='text-uppercase text-muted col-md-8'>Payment Method</h5>
                                <div className="col-4 d-flex">
                                  <img src="assets/img/icon/pay/visa.svg" alt="" className="img-fluid icon-pay" />
                                  <img src="assets/img/icon/pay/master-card.svg" alt="" className="img-fluid icon-pay" />
                                  <img src="assets/img/icon/pay/american-express.svg" alt="" className="img-fluid icon-pay" />
                                  <img src="assets/img/icon/pay/discover.svg" alt="" className="img-fluid icon-pay" />
                                </div>
                            </div>
                              {checkout ?
                              (
                                <>
                                {/* <p className='mt-5'>Please select a payment method.</p> */}
                               
                                {/* <Elements stripe={stripePromise}>
                                  <CheckoutForm />
                                </Elements> */}
                                
                                {/* <form name="c_form" action="http://3.20.64.228/control_panel/api/payment/paynow" method="POST"> */}
                                <div className="App">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
                                <form name="c_form" id="c_form"  >
                               
                                    <div>
                                        <p><input type="radio" name="pay_mode" value="bank_transfer"></input> &nbsp;Bank Transfer </p>
                                        <p><input type="radio" checked="check" name="pay_mode" value="online"></input> &nbsp;Online Pay</p> 
                                    </div>  
                                    <input type="hidden" name="params" defaultValue={JSON.stringify(ids)}></input>
                                    <input type="hidden" name="email" id="checkout_email" defaultValue={finalemail} ></input>
                                    
                                    <input type="hidden" name="billiaddress1" id="billiaddress1" defaultValue={billadd1} ></input>
                                    <input type="hidden" name="billiaddress2" id="billiaddress2" defaultValue={billadd2} ></input>
                                    <input type="hidden" name="billicity" id="billicity" defaultValue={billcity} ></input>

                                    <input type="hidden" name="shipaddress1" id="shipaddress1" defaultValue={shipadd1} ></input>
                                    <input type="hidden" name="shipaddress2" id="shipaddress2" defaultValue={shipadd2} ></input>
                                    <input type="hidden" name="shipcity" id="shipcity" defaultValue={shipcity} ></input>
                                    <input type="hidden" name="customer_id" id="customer_id" defaultValue={customer} ></input>
                                    <input type="hidden" name="customername" id="customername" defaultValue={customername} ></input>
                                    <input type="hidden" name="product_type" id="product_type" defaultValue={JSON.stringify(product_type)} ></input>
                                    <input type="hidden" name="instructions" id="instructions" defaultValue={deliveryguide} ></input>
                                    <input type="hidden" name="coupon_code" id="coupon_code" defaultValue={couponval} ></input>
                                    <Button className='w-100 btn btn-outline-default flex-fill' type="submit" >
                                          Pay Online
                                    </Button> 
                                    {/* <Button className='w-100 btn btn-outline-default flex-fill' type="button" onclick="getintent();">
                                          Pay Online
                                    </Button> */
                                        
    }
                                    </form>
                                
                                </>
                              )
                              :
                              ''}
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-md-4">
              <CartSummary couponval={couponval}  setcouponval={setCouponval} />
            </div>

          </div>
        </div>
      </section>



    </div>
  )
}

export default Checkout