import React from 'react'
import { Link, useParams, useSearchParams, useNavigate,Navigate,Route,useLocation } from 'react-router-dom'
function OrderStatus(props) {
 
    const {id} = useParams('id');
    var querystr = id.split("&")
    console.log(querystr[0])
    console.log(querystr[1])
    console.log(id)
    localStorage.removeItem("indexvalue");
    localStorage.removeItem("availableCartProductIds");
    localStorage.removeItem("productsbag");
    props.setCartItem([]);
    const renderSwitch = () => {
        switch (querystr[0]) {
        case 'succeeded':
            return ('Success! Payment received.');
            break;

        case 'processing':
            return ("Payment processing. We'll update you when payment is received.");
            break;

        case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            return ('Payment failed. Please try another payment method.');
            break;

        default:
            return ('Something went wrong.');
            break;
        }
    }
    return (
      <>
      <section id="breadcrumbRow">
          <h2>
            {renderSwitch(querystr[0])}
          </h2>
          <div className="container">
            <input type="hidden" value={querystr[1]}></input>
            <input type="hidden" value={querystr[2]}></input>
              <div className="row pageTitle m0">
                  {/* <div className="col-12">
                      <h4 className="fleft">About us</h4>
                      <ul className="breadcrumb fright">
                          <li><a href="index.html">home</a></li>
                          <li className="active">About us</li>
                      </ul>
  
                  </div> */}
              </div>
          </div>
      </section>
      {/* <section className='py-3'>
          <div className='container'>
              <div className='row'>
                  <div className='col-12 text-center text-success'>
                      <h3 className=''>Your order no. is {id}</h3>
                  </div>
              </div>
          </div>
      </section> */}
      </>
    )
}

export default OrderStatus