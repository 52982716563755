import React,{useState,useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom'

import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'





const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderLeft: '0',
    borderRight: '0',
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<FaAngleRight sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'transparent'
        : 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    padding: 0,
    borderBottom: 0
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //padding: theme.spacing(2),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
    //paddingRight: 0,
    //paddingLeft: 0,
  }));


function CartSummary(props) {
  
    var is_discounted = false;
    let [jesdada, setJesdata] = useState([]);
    const [price, setPrice] = useState([]);
    let [coupons,setCoupons] =useState(0);
    let [discount,setDiscount  ] = useState([]);
    let [error,setError] = useState();
    var formData = new FormData();
    const[taxamount,setTaxamout] = useState([]);
    let [fortax,setFortax] = useState(0);
    async function applyCoupon(){
      var customer_id = localStorage.getItem("customer_id");
        var requestdata = {
            coupon: document.getElementById('coupon_text').value,
        };
        formData.append('json', JSON.stringify(requestdata));
        let requestOptions = {
            method: 'POST',
            body: formData 
        };
        var resp = await fetch("http://3.20.64.228/control_panel/api/server/apply_coupon",requestOptions);
        var data = await resp.json();

        var response = data.data;
        
        if(data.status==1){
          
            if(is_discounted == false){
                var flag=1;
                var final_amount_before = document.getElementById('purchased_amount').value;
                if(response.min_purchase_amount > 0){
                  
                    if( parseFloat(response.min_purchase_amount) > parseFloat(final_amount_before) ){
                       
                       setError("Minimum purchase amount is RM " + response.min_purchase_amount);
                       flag=0; 
                       return;
                    }
                }
                if(response.applies == 0){
                    setError("Maximum number reached " );
                    flag=0; 
                    return;
                }
                if(response.is_first == 1){
                   
                    if(customer_id==null){
                        window.location.href = window.location.origin + '/login';
                    }
                    var requestdata = {
                        customer_id: customer_id,
                    };
                    formData.append('customer', JSON.stringify(requestdata));
                    let requestOptions = {
                        method: 'POST',
                        body: formData 
                    };
                    var pulldata = await fetch("http://3.20.64.228/control_panel/api/server/check_firsttime",requestOptions);
                    var userdata = await pulldata.json();
          
                    var user_response_code = userdata.status;
                   
                    if(user_response_code==0){
             
                        setError(userdata.message);
                        flag=0; 
                        return;
                    }
                    
                }
                if(response.is_one_time==1){
                    if(customer_id==null){
                        window.location.href = window.location.origin + '/login';
                    }
                    var requestdata = {
                        customer_id: customer_id,
                        coupon_code:document.getElementById('coupon_text').value
                    };
                    formData.append('customer', JSON.stringify(requestdata));
                    let requestOptions = {
                        method: 'POST',
                        body: formData 
                    };
                    var pulldata = await fetch("http://3.20.64.228/control_panel/api/server/check_is_one",requestOptions);
                    var userdata = await pulldata.json();          
                    var user_response_code = userdata.status;
                    if(user_response_code==0){
                        
                        setError(userdata.message);
                        flag=0; 
                        return;
                    }
                }
                var discountAmoun;
                if (response.type == 'percent') {
                    var substract_num = (response.amount / 100) * final_amount_before;
                    var final_amount = final_amount_before - substract_num;
                    discountAmoun = final_amount;
                }
                if (response.type == 'flat') {
                    var final_amount = final_amount_before - response.amount;
                    discountAmoun = response.amount;
                }

                
                if(jesdada.id!=null){
                    var taxval = parseFloat(jesdada.value)/100;
                    var tax_amount = final_amount * taxval;
                    tax_amount = (Math.round(tax_amount * 100) / 100).toFixed(2);
                    setFortax(tax_amount);
                    //setTaxamout(taxamount);
                    //final_amount = final_amount + taxamount;
                }
                setPrice(final_amount);
                setCoupons(1);
                localStorage.setItem("coupon_val",document.getElementById('coupon_text').value)
                //document.getElementById('coupon_code').value = document.getElementById('coupon_text').value
                setDiscount(document.getElementById('coupon_text').value);
                setError('');
                props.setcouponval(document.getElementById('coupon_text').value);
            }
        }else{
            setError(data.message);
        }
    } 
    const cancelCoupon =()=>{
        setPrice([]);
        setCoupons(0);
        setDiscount([]);
        props.setcouponval('');
        setFortax(0);
        //document.getElementById('coupon_code').value = '';
    }
  async function getTax(){
      let requestOptions = {
        method: 'POST'
      };
      var response = await fetch("http://3.20.64.228/control_panel/api/server/gettax",requestOptions);
      var data = await response.json();
      setJesdata(data.data);
      props.settaxnval(document.getElementById('coupon_text').value);
  }  
  useEffect(()=>{
      getTax();
  },[])
    const errorMessage = () => {
      return (
          <>
          {error ?
          <div className="messages">
            <div className="error alert d-flex align-items-center alert-danger">
              {error}  {/*  <button type="button" className="btn-close ms-auto" data-bs-dismiss="alert" aria-label="Close"></button> */}
            </div>
          </div>
          :
          ''}
          </>
      );
      };

        var existing_cart = localStorage.getItem('productsbag') == null ? [] : JSON.parse(atob(localStorage.getItem('productsbag')));
        var total_price = 0;
        let navigate = useNavigate()
        for(var i = 0; i <  existing_cart.length; i++)
        {
            var ringsizeprice = 0; 
            if(existing_cart[i]['product_type']=="compound"){

              var ringsizeprice =existing_cart[i]['ring_price'];
              if(ringsizeprice==""){
                ringsizeprice = 0;
              }  
              total_price += parseInt(existing_cart[i]['ring']['sellprice'])+parseFloat(existing_cart[i]['diamond']['total_price']) + parseInt(ringsizeprice)
            }else if(existing_cart[i]['product_type']=="simple"){
                var ringsizeprice =existing_cart[i]['ring_price'];
                if(ringsizeprice==""){
                  ringsizeprice = 0;
                } 
                total_price +=  parseInt(existing_cart[i]['finejewellery'][0]['sellprice'] ) +  parseInt(ringsizeprice);
                
            }
        }
      
        var tax_amount=0;
        if(jesdada.id!=null){
          
          var taxval = parseFloat(jesdada.value)/100;
          var tax_amount =parseFloat(total_price * taxval) ;
          tax_amount = (Math.round(tax_amount * 100) / 100).toFixed(2);
        }
       
        // var tax_amount = total_price * taxval;
        // setTaxamout(taxamount);

        const removecart = (index) => {

        var available_in_cart = JSON.parse(atob(localStorage.getItem("productsbag")));
        var available_in_cart_id = JSON.parse(atob(localStorage.getItem("availableCartProductIds")));
        
        available_in_cart[index] = "";
        available_in_cart_id[index] = "";
        
        var tempArrayproduct = available_in_cart.filter(function (el) {
            return el != '';
        });
        var tempArrayproductid = available_in_cart_id.filter(function (el) {
            return el != '';
        });
        if(tempArrayproduct.length > 0)
        {
            localStorage.setItem("productsbag",btoa(JSON.stringify(tempArrayproduct)));
        }
        else
        {
            localStorage.setItem("productsbag",btoa(JSON.stringify([])));
        }
        if(tempArrayproductid.length > 0)
        {
            localStorage.setItem("availableCartProductIds",btoa(JSON.stringify(tempArrayproductid)));
        }
        else
        {
            localStorage.setItem("availableCartProductIds",btoa(JSON.stringify([])));
        }

        props.setCartItem(tempArrayproduct);
    }


  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <>
        <div className="card shadow border-0 card-summary">
          <div className="card-body">
            <List className="p-0">
              <li className="d-flex justify-content-between align-items-start mb-2">
                <div className="me-auto">
                  Subtotal
                </div>
                <span className="">{price>0 ? (Math.round(price * 100) / 100).toFixed(2):(Math.round(total_price * 100) / 100).toFixed(2)}</span>
                <input type="hidden" name = "purchased_amount" value ={total_price} id="purchased_amount"></input>
              </li>
              <li className="d-flex justify-content-between align-items-start mb-2">
                <div className="me-auto">
                  Tax
                </div>
                <span className="">{fortax>0?fortax:tax_amount}</span>
              </li>
              <li className="d-flex justify-content-between align-items-start mb-2">
                <div className="me-auto">
                  US & Int. Shipping
                </div>
                <span className="">Free</span>
              </li>
              
                {errorMessage()}
             
              
              <li className= {coupons==1 ? 'd-none':'my-4'} id="coupon_container">

                <Accordion expanded={expanded === 'promoCode'} onChange={handleChange('promoCode')}>
                  <AccordionSummary aria-controls="promoCoded-content" id="promoCoded-header">
                    <Typography>Promo Code</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <small className='text-muted d-block mb-2'>Entering a new promo code will <b>replace</b> the current <b>25% Off sale discount</b>.</small>

                    <div className="d-flex">
                      <input className="form-control me-2" type="text" name="coupon_text" id="coupon_text" />
                      <button className="btn btn-primary" onClick={applyCoupon}>Apply</button>
                    </div>
                  </AccordionDetails>
                </Accordion>
              
              </li>
              <li className= {coupons==1 ? 'my-4':'d-none'}>
                  <div className="d-flex">
                      <span className="badge bg-light text-dark border rounded-pill">
                        {discount} 
                        <span className="badge bg-danger rounded-pill ms-2" onClick={cancelCoupon}>
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </span>
                      
                  </div>
              </li>
              <li className="d-flex justify-content-between align-items-start mb-3">
                <div className="me-auto">
                  <div className="fw-bold fs-5 text-muted">Total</div>
                </div>
                <span className="fw-bold fs-5 text-muted">{price>0 ?parseFloat((Math.round(price * 100) / 100).toFixed(2))+parseFloat((Math.round(fortax * 100) / 100).toFixed(2)):parseFloat((Math.round(total_price * 100) / 100).toFixed(2))+parseFloat((Math.round(tax_amount * 100) / 100).toFixed(2))}</span>
              </li>
              <li className="d-flex justify-content-between align-items-start">
                <div className="me-auto">
                  <div className=""><span className="text-muted">or</span> $634.50 x 6</div>
                  Interest Free Payments <Button>Learn More</Button>
                </div>
                <span className=""></span>
              </li>
              <li><hr /></li>
              <li className="d-flex flex-column">
                {/* <Button className="btn btn-outline-default flex-fill mb-2" href='/checkout'>Checkout</Button> */}
                <Link className="btn btn-outline-default flex-fill mb-2" to={'/checkout'}>Checkout</Link>
                {/* <span className='d-block text-center'>OR</span>
                <button className="btn btn-default flex-fill fw-bold mt-2">PayPal</button> */}
              </li>
              
             
            </List>
            
          </div>
        </div>
    </>
  )
}

export default CartSummary
